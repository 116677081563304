import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3441252649/src/src/templates/faq/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Brand consent`}</h2>
    <h3>{`What is cardholder and merchant consent and how can I collect this?`}</h3>
    <p>{`Cardholders and merchants alike must provide consent in order for their transactions to be tracked by Fidel. In both cases, a client cannot proceed until this criteria has been met.`}</p>
    <h3>{`Merchant Consent`}</h3>
    <p>{`On the brand level a client must declare the consent status before they are able to create the locations which are aligned to it.`}</p>
    <h5>{`Dashboard Merchant Consent`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/dashboard-merchant-consent.png",
        "alt": "Dashboard Merchant Consent",
        "title": "Dashboard Merchant Consent"
      }}></img></p>
    <p>{`The client has two options at this stage:`}</p>
    <ul>
      <li parentName="ul">{`By invitation. This allows the user to enter the details for their contact at the brand, this will have the effect of sending an email notification to that representative which contains a link that the recipient must click on. (`}<b>{`NOT RECOMMENDED!`}</b>{`)`}</li>
    </ul>
    <h5>{`Dashboard Invitation`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/dashboard-invitation.png",
        "alt": "Dashboard Invitation",
        "title": "Dashboard Invitation"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Auto-approve. By far the more widely used option, this allows the client to automatically auto approve their brand with the consent status immediately being set to approved. (`}<b>{`RECOMMENDED`}</b>{`)`}</li>
    </ul>
    <h5>{`Create brand`}</h5>
    <p><img parentName="p" {...{
        "src": "https://docs.fidel.uk/assets/images/create-brand.png",
        "alt": "Create brand",
        "title": "Create brand"
      }}></img></p>
    <p><strong parentName="p">{`Note`}</strong>{`: this option should solely be used on the proviso that the client has obtained merchant consent through their own agreement with the brand. Whilst it is the customer’s responsibility to do this, Fidel can and will be audited by the card networks who are within their rights to ask for evidence of this.`}</p>
    <h2>{`Pause Brand`}</h2>
    <h3>{`Can I pause a brand?`}</h3>
    <p>{`Due to Fidel’s own merchant onboarding processes, this is not currently possible as a brand can only either be onboarded or off-boarding. As it stands, you can only offboard the merchant and when applicable re-onboard the merchant. Usual merchant onboarding timelines apply.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      